import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { RuleSet, UseCase, Application, AbstractSecurityElement, SecurityApplication } from '@smartobjx/smart.objx.models';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { HttpErrorResponse } from '@angular/common/http';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Mediator from '../core-services/mediator/access.mediator';
import { UploadFileComponent } from '../upload-file/upload-file.component';

@Component({
    selector: 'application-browser',
    templateUrl: './application-browser.component.html',
    styleUrls: ['./application-browser.component.scss']
})
export class ApplicationBrowserComponent implements OnInit, OnDestroy {
    @Output() closeBrowser = new EventEmitter<any>();
    @Output() selectApplicationEvent = new EventEmitter<any>();
    
    i_NameApplication: string;
    isEmpty
    isActive
    isLoading
    updateRefresh: any;
    get info(): MatSnackBar { return this._info; }

    onDestroy$: Subject<boolean> = new Subject();
    constructor(
        public _dialog: MatDialog,
        private _info: MatSnackBar,
        private mediator: Mediator,
        private authService: AuthService
    ) { }
    ngOnDestroy(): void {
        this.onDestroy$.next(true);
        this.onDestroy$.unsubscribe();
    }
    ngOnInit(): void {
        this.GetAllAppications();
    }
    public GetAllAppications( refresh: () => void = null) {
        this.mediator.LoadingObservable.next(true)
        this.mediator.GetAllApplications().subscribe((apps) => {
            this.applications = apps;
            this.applicationsFull = apps;
            this.model = apps;
            this.mediator.LoadingObservable.next(false)
            if(!_.isNil(refresh)) {refresh();}
        });
    }

    public GetSecurityApplication(oid, refresh: () => void = null) {
        this.mediator.LoadingObservable.next(true)
        this.mediator.GetSecurityApplication(oid).subscribe((app    ) => {
            var foundIndex = this.applications.findIndex(x => x.OID == app.OID);
            this.applications[foundIndex] = app;
            foundIndex = this.applicationsFull.findIndex(x => x.OID == app.OID);
            this.applicationsFull[foundIndex] = app;
            foundIndex = this.model.findIndex(x => x.OID == app.OID);
            this.model[foundIndex] = app;
            this.mediator.LoadingObservable.next(false)
            if(!_.isNil(refresh)) {refresh();}
        });
    }
    selectApplication(app) {
        this.selectApplicationEvent.emit(app)
    }
    //#region Event Handlers
    onCreate() {
        let model = new AbstractSecurityElement()
        model.Version = new Date();

        this.closeBrowser.emit({ model: model, newApplication: true })

    }
    openUploadManifest(){
        const dialogRef = this._dialog.open(UploadFileComponent,{
            data: {
                mediator: this.mediator
              }
        });

        dialogRef.afterClosed().subscribe(result => {
          if(result == true){
            this.GetAllAppications()
            this.info.open("Application saved", '', {
                duration: 2000,
                verticalPosition: 'top',
                horizontalPosition: 'end',
                panelClass: 'info-primary'
            });
          }
        });
    }
    onEdit(model: SecurityApplication) {
        this.mediator.LoadingObservable.next(true)
         this.mediator.GetSecurityApplication(model.OID).subscribe((modelSecurityItem)=>{
            this.mediator.LoadingObservable.next(false)
            this.closeBrowser.emit({ model: modelSecurityItem, newApplication: false })

          })

    }

    filterUseCases(type: string) {
        switch (type) {
            case 'active':
                this.applications = this.applicationsFull.filter(o => !(o as any).Disabled);
                break;
            case 'disabled':
                this.applications = this.applicationsFull.filter(o => (o as any).Disabled);
                break;
            default:
                this.applications = this.applicationsFull;
        }
    }


    //#region list events



    searchPreview(e: any, el: any) {
        if (e.key === 'Enter') {
            this.search(el);
        }
        // this.updateAutocomplete( el );
    }
    search(el: any, clean: boolean = true) {
        const value = el.value.trim();
        if (value && Tools.isNotInArray(value, this.searchChips))
            this.searchChips.push(value.toLowerCase());
        if (clean) el.value = '';
    }
    removeChip(value: string) {
        const i = this.searchChips.indexOf(value);
        this.searchChips.splice(i, 1);
    }
    clearSearch() {
        this.searchChips = [];
        this.currentSearch = '';
    }

    // #region Construction & Finalization


    // #endregion

    // #region Properties
    get model(): SecurityApplication[] {
        return this.i_Model;
    }

    @Input()
    set model(newModel: SecurityApplication[]) {
        this.i_Model = newModel;
    }

    get selectedUseCaseID(): string {
        return this.i_SelectedUseCaseID;
    }

    get selectedApplicationIndex(): number {
        return this.applicationsFiltered ? this.applicationsFiltered.map(o => o.OID).indexOf(this.selectedUseCaseID) : -1;
    }

    get applicationsFull(): SecurityApplication[] {
        return this.i_ApplicationsFull;
    }
    set applicationsFull(newApss: SecurityApplication[]) {
        this.i_ApplicationsFull = newApss;
        this.applications = newApss;
    }

    get applications(): SecurityApplication[] {
        return this.i_Applications;
    }

    set applications(apps: SecurityApplication[]) {
        this.i_Applications = apps;
    }

    get applicationsFiltered(): SecurityApplication[] {
        return this.searchList.length
            ? this.applicationsFull.filter(o =>
                this.searchList
                    .filter(c =>
                        Tools.isInArray(c, o.Name.toLowerCase())
                    )
                    .length
            )
            : this.applicationsFull;
    }
    get searchList(): string[] {
        const list = this.searchChips.slice();
        if (this.currentSearch) {
            list.push(this.currentSearch.toLowerCase());
        }
        return list;
    }
    searchChips: string[] = [];
    private currentSearch: string;
    private autocompleteList: string[];

    private i_Model: SecurityApplication[];
    private i_Applications: SecurityApplication[];
    private i_SelectedUseCaseID: string;
    useCasesError: string;
    debugging: RuleSet;



    showFilterMessage: string;
    private i_ApplicationsFull: SecurityApplication[];
    // #endregion

}

<!-- <div>{{ simpleDate(model) }} | arrow pressed: {{ arrowPressed }}</div> -->
<div class="mat-mdc-floating-label custom-label">{{placeholder}}</div>
<div class="custom-date" #container (keydown)="keyPressed( $event )" (keyup)="keyReleased( $event )">
  <mat-form-field style="z-index:1" class="access month">

    <input matInput type="number" #month
           [placeholder]="placeholder" style="width: 18px;     margin-left: 10px;"
           [(ngModel)]="dateMonth"
           (focus)="setFocused()" (blur)="removeFocused()" />
    <div class="divider">/</div>
  </mat-form-field>
  <mat-form-field style="margin-top: 0;" class="access day">
    <input matInput type="number" #day style="width: 18px;"
           [(ngModel)]="dateDay"
           (focus)="setFocused()" (blur)="removeFocused()" />
    <div class="divider">/</div>
  </mat-form-field>
  <mat-form-field style="margin-top: 0; " class="access year">
    <input matInput type="number" #year style="width: 55px;"
           max="9999" min="2020"
           [(ngModel)]="dateYear"
           (focus)="setFocused()" (blur)="removeFocused()" />
  </mat-form-field>
  <mat-form-field style="margin-top: 0; " class="access hour">
    <input matInput type="number" #hours style="width: 24px;"
           [(ngModel)]="dateHour"
           (focus)="setFocused()" (blur)="removeFocused()" />
    <div class="divider">:</div>
  </mat-form-field>
  <mat-form-field style="margin-top: 0;" class="access minutes">
    <input matInput type="number" #minutes style="width: 24px;"
           [(ngModel)]="dateMinute"
           (focus)="setFocused()" (blur)="removeFocused()" />
  </mat-form-field>
  <mat-form-field style="margin-top: 0;" class="access AMPM">
    <input matInput style="width: 44px;" readonly
           [value]="twelveHours"
           (keyup)="onKeyUpAMPM( $event )" (click)="onClickAMPM( $event )" (keydown)="onKeyDownAMPM( $event )"
           (focus)="setFocused()" (blur)="removeFocused()" />
  </mat-form-field>
  <mat-form-field style="margin-top: 0;" class="access calendar">
    <input matInput readonly tabindex="-1"
           (focus)="setFocused()" (blur)="removeFocused()" />
    <mat-icon matSuffix (click)="picker.open()">calendar_today</mat-icon>
    <input type="hidden" [matDatepicker]="picker" [(ngModel)]="dateForPicker" />
  </mat-form-field>
  <mat-datepicker #picker></mat-datepicker> <!--[startAt]="minDate" -->
</div>

<mat-list cdkDropList (cdkDropListDropped)="drop($event)" [style.overflow-y]="overflow" [style.height]="height">
    <mat-list-item *ngFor="let record of (sortAlphabetically ? modelSorted : model); let i = index" [class.withchanges]="this.showVersionOn( record )"
      cdkDrag
      [cdkDragDisabled]="!this.showSorter"
      cdkDragBoundary=".cdk-drop-list" 
      cdkDragLockAxis="y"
      class="record"
      [class.selected]="selectedIndex==i"
      [class.disabled]="recordIsDisabled(record)"
      [class.can-hover]="canHover" [matTooltip]="Application" matTooltipPosition="after"
    >        
      <mat-icon mat-list-icon *ngIf="this.showSorter" class="handler" cdkDragHandle>drag_handle</mat-icon>
      <mat-icon mat-list-icon *ngIf="this.showType" class="type" >{{ getIconByType(record) }} afasf</mat-icon>
      <div *ngIf="this.showEdit && this.isNotRepleaceable(record);else normal" class="name">
          <div class="not-rep">NOT REPLACEABLE</div>
          <div class="nr-name">{{ record.Name }}</div>
      </div>
      <ng-template #normal>
        <div class="name" [style.font-size]="nameFontSize" [innerHTML]="formatName(record.Name)"></div>
        <div *ngIf="this.showVersionOn( record )" class="version">Edited on {{ toShortDate(record.Version) }}</div>
      </ng-template>
      <div class="options">
          <ng-container *ngIf="innerWidth>1200;else mobile">
              <button *ngIf="this.showEdit && !this.isNotRepleaceable(record)" mat-icon-button color="primary" (click)="this.onEdit.emit(record)" matTooltip="Edit" [disabled]="recordIsDisabled(record)">
                  <mat-icon>create</mat-icon>
              </button>
              <button *ngIf="this.showDeleteAllRelationship && !this.isNotRepleaceable(record)" mat-icon-button color="primary" (click)="this.onDeleteAllRelationship.emit(record)" matTooltip="Delete parents" [disabled]="recordIsDisabled(record)">
                  <mat-icon>link_off</mat-icon>
              </button>
              <button *ngIf="this.showEdit && this.isNotRepleaceable(record)" mat-icon-button color="primary" (click)="this.onEdit.emit(record)" matTooltip="Info" [disabled]="recordIsDisabled(record)">
                  <mat-icon>info</mat-icon>
              </button>
              <button *ngIf="this.showSelect" mat-icon-button color="primary" (click)="this.selectApplication.emit(record)" matTooltip="Select" [disabled]="recordIsDisabled(record)">
                  <mat-icon>info</mat-icon>
              </button>
          
              
          <ng-container *ngIf="this.showVersions">   
              <custom-datepicker 
                  [(selectedDate)]="selectedDate"
                  [initialDate]="selectedDate"
                  [versionDates]="versionDates"
                  [ruleId]="record.OID"
                  [isLoading]="versionsIsLoading"
                  (onChange)="onChangeSelectedDate( record, $event )"
                  (openVersions)="getVersions( $event )"
                  class="custom-datepicker"
                  color="primary"
                  [minDate]="firstVersionsDate"
              ></custom-datepicker>
          </ng-container>
              <button *ngIf="this.showDelete && !this.isNotRepleaceable(record)" mat-icon-button color="primary" (click)="this.onDelete.emit(record)" [matTooltip]="deleteTooltip( record )">
                  <mat-icon *ngIf="!recordIsDisabled(record)">close</mat-icon>
                  <mat-icon *ngIf="recordIsDisabled(record)">check_circle_outline</mat-icon>
              </button>
          </ng-container>
          <ng-template #mobile>
              <button mat-icon-button color="primary" [matMenuTriggerFor]="menu" *ngIf="showMobileOptions">
                  <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #menu="matMenu">
                  
                  <button *ngIf="this.showDeleteAllRelationship" mat-icon-button color="primary" (click)="this.onDeleteAllRelationship.emit(record)" matTooltip="Delete all Relationship" [disabled]="recordIsDisabled(record)">
                      <mat-icon>link_off</mat-icon>
                  </button>
  
                      <button *ngIf="this.showEdit" mat-icon-button color="primary" (click)="this.onEdit.emit(record)" matTooltip="Edit" [disabled]="recordIsDisabled(record)">
                          <mat-icon *ngIf="!this.showInfo">create</mat-icon>
                          <mat-icon *ngIf="this.showInfo">info</mat-icon>
                      </button>
                      <button *ngIf="this.showDebug" mat-icon-button color="primary" (click)="this.onDebug.emit(record)" matTooltip="Test" [disabled]="recordIsDisabled(record)">
                          <mat-icon>bug_report</mat-icon>
                      </button>
                      <button *ngIf="this.showSelect" mat-icon-button color="primary" (click)="this.selectApplication.emit(record)" matTooltip="Select" [disabled]="recordIsDisabled(record)">
                          <mat-icon>info</mat-icon>
                      </button>
                      <ng-container *ngIf="this.showVersions">   
                      <div style="display: inline;" (click)="$event.stopPropagation()" (keydown.tab)="$event.stopPropagation()">
                          <custom-datepicker *ngIf="this.showVersions"
                              [(selectedDate)]="selectedDate"
                              [initialDate]="selectedDate"
                              [versionDates]="versionDates"
                              [ruleId]="record.ConfigId || record.OID"
                              [isLoading]="versionsIsLoading"
                              (onChange)="onChangeSelectedDate( record, $event )"
                              (openVersions)="getVersions( $event )"
                              class="custom-datepicker"
                              color="primary"
                              [minDate]="firstVersionsDate"
                          ></custom-datepicker>
                      </div>
                      </ng-container>
  
                      <button *ngIf="this.showDelete" mat-icon-button color="primary" (click)="this.onDelete.emit(record)" [matTooltip]="deleteTooltip( record )">
                          <mat-icon *ngIf="!recordIsDisabled(record)">close</mat-icon>
                          <mat-icon *ngIf="recordIsDisabled(record)">check_circle_outline</mat-icon>
                      </button>
              </mat-menu>
          </ng-template>
      </div>
    </mat-list-item>
  </mat-list>
<mat-card appearance="outlined" class="full-height mat-main-card access" [class.mat-card-raised]="isActive">
  <mat-progress-bar mode="indeterminate" class="card-top" [class.hidden]="!isLoading"></mat-progress-bar>

  <mat-card-title class="overline" style="min-width: 266px;">{{isNew? 'NEW': 'EDIT'}} ENTITLEMENT </mat-card-title>
  <mat-icon class="close" (click)="CloseRoleBtn()">close</mat-icon>
  <ng-container *ngIf="!isLoading">
    <mat-card-content style="height: 75vh;">
      <version-caption *ngIf="versionDateData" [selectedDate]="versionDateData.date"
                       [showTime]="versionDateData.showTime" [isNotInitialDate]="versionDateData.altClass"></version-caption>
      <form [formGroup]="form">
        <mat-form-field class="custom" style="margin-top: 13px;">
          <mat-label>Name</mat-label>
          <input matInput formControlName="name" #nameInput />
        </mat-form-field>
        <mat-form-field class="custom">
          <mat-label>Description</mat-label>
          <input matInput formControlName="description" #nameInput />
        </mat-form-field>
        <custom-date [(model)]="i_startDate" [(forceInvalid)]="startDateForceInvalid" style="margin-top: -26px;"></custom-date>
        <!-- list of child rules -->
        <mat-form-field class="custom">
          <mat-label>Permission</mat-label>
          <mat-select formControlName="permission">
            <mat-option value="Allow">Allow</mat-option>
            <mat-option value="Deny">Deny</mat-option>
            <mat-option value="Revoke">Revoke</mat-option>
          </mat-select>
        </mat-form-field>
      </form>


    </mat-card-content>
    <!-- footer -->
    <mat-card-actions fxFlexAlign="end" align="end" class="card-options-entitlements">
      <mat-divider></mat-divider>
      <div class="button-container">
        <button mat-raised-button type="button" color="primary" class="toolbar-bottom-button" [disabled]="formIsDisabled"
                (click)="this.onSaveModel()">
          Save Entitlement
        </button>
      </div>

    </mat-card-actions>
  </ng-container>

  <!-- POP UPS -->
  <ng-template #startDateMinVerification>
    <div class="h6 start-date-message" matDialogTitle>
      <mat-icon>warning</mat-icon>
      <div class="text">Attention</div>
    </div>
    <mat-dialog-content class="start-date-message">


      The chosen Start date has already passed. <br />
      Do you want to make these changes active immediately?

    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button matDialogClose (click)="fixStartDateAndSave()">Yes</button>
      <button mat-raised-button matDialogClose color="primary" (click)="markStartDateAsDirty()">No</button>
    </mat-dialog-actions>
  </ng-template>
</mat-card>

import { Injectable } from '@angular/core';
import { AccessService } from '@smartobjx/smart.connectors';
import { AuthService } from '../authentication/auth.service';
import { AbstractSecurityElement, Entitlement, Privilege, Role, SecurityApplication } from '@smartobjx/smart.objx.models';
import { CustomValidator } from 'src/app/shared/Validation';
import { Observable, Subject } from 'rxjs';

export function factory() {
  return (_server: AccessService, _auth: AuthService): Mediator => {
    return new Mediator(_server, _auth);
  };
}

@Injectable({
  providedIn: 'root'
})
export default class Mediator {
  LoadingObservable: Subject<boolean> = new Subject();
  ApplicationId: any

  createApplicationFromAbstract(model: any, resetForm: () => void, reloadParent: () => void, newApplication: boolean) {
    let application: SecurityApplication = new SecurityApplication();
    application.Name = model.Name;
    application.Version = model.Version;
    model.ApplicationId = this.ApplicationId
    model.Version = CustomValidator.fixDate(model.Version)
    this.saveConfigWithApplication(newApplication ? null : this.ApplicationId, model)
      .subscribe((config: AbstractSecurityElement) => {
        if (config != null) {
          this.saveApplicationDirect(config, newApplication ? null : this.ApplicationId)
            .subscribe((application: SecurityApplication) => {
              // viewController.selectedUseCaseID = newUseCase.OID;
              resetForm();
              reloadParent();
            });
        } else {
          throw 'something went wrong';
        }
      });
  }

  saveApplication(application: any) {
    let app = new SecurityApplication({
      Name: application.Name,
      Version: application.Version,
      OID: application.OID,
      Description: application.Description,
    });
    (app as any).PrimChildren = application.PrimChildren
    return this.server.saveApplication(null, app)

  }

  GetAllApplications() {
    return this.server.getAllSecurityApplications()
  }

  GetSecurityApplication(Oid) {
    return this.server.getSecurityApplication(Oid)
  }
  findSecurityItemWith(Oid) {
    return this.server.findSecurityItemWith(Oid)
  }
  saveApplicationDirect(config: AbstractSecurityElement, applicationId) {
    return this.server.saveApplication(null,
      new SecurityApplication({
        Name: config.Name,
        //SecurityID: config.OID,
        Version: config.Version,
        OID: applicationId,

      })
    );
  }
  saveConfigWithApplication(applicationID: string, config: AbstractSecurityElement): Observable<any> {
    return applicationID
      ? this.server.saveAbstractSecurity(applicationID, null, config)
      : this.server.saveAbstractSecurity('00000000-0000-0000-0000-000000000000', null, config);
  }

  checkLoginAndGetName(ownerId: string, subscriberToken: string, perspective) {
    this.server.configuration.SubscriberToken = subscriberToken;
    this.server.configuration.POVToken = ownerId;
    return this.server.checkLoginAndGetName(perspective);
  }
  private async save(config: AbstractSecurityElement): Promise<AbstractSecurityElement> {
    let actionMessage = "The changes were saved successfully";

    let resp = await this.server.saveAbstractSecurity(this.ApplicationId, null, config).toPromise();

    return resp;
  }

  saveAbsctract(parentModel: any, role: AbstractSecurityElement, resetForm: (roleAdded?:any) => void = null) {
    this.LoadingObservable.next(true)
    let cloneParent = _.cloneDeep(parentModel)
    if (_.isNil(cloneParent.PrimChildren)) { cloneParent.PrimChildren = new Array<AbstractSecurityElement>() }
    let itemFound = cloneParent.PrimChildren.findIndex((item) => item.OID == role.OID)
    if (itemFound < 0) {
      cloneParent.PrimChildren.push(role)
      this.saveApplication(cloneParent).subscribe((app) => {
        const roleAdded = (app as any).PrimChildren.find(x=> x.Name == role.Name);
        // parentModel = app
        resetForm(roleAdded)
      })
    } else {
      this.server.saveAbstractSecurity(cloneParent.OID, null, role).subscribe((item) => {
        console.log(item)
        // parentModel.PrimChildren[itemFound] = item
        resetForm()
      })
    }

  }

  

  DeleteLinkRelationship(parentModel: any, role: AbstractSecurityElement, resetForm: () => void = null) {
    this.LoadingObservable.next(true)
    let cloneParent = _.cloneDeep(parentModel)
    role.Version = new Date()

    if (_.isNil(cloneParent.PrimChildren)) { cloneParent.PrimChildren = new Array<AbstractSecurityElement>() }
    this.server.saveAbstractSecurity(null, null, role).subscribe((item) => {
      // parentModel.PrimChildren[itemFound] = item
      resetForm()
    })


  }

  saveEntitlement(parentModel: any, entitlement: Entitlement, resetForm: () => void = null) {
    this.LoadingObservable.next(true)
    let cloneParent = _.cloneDeep(parentModel)
    if (_.isNil(cloneParent.PrimEntitlements)) { cloneParent.PrimEntitlements = new Array<Entitlement>() }
    let itemFound = cloneParent.PrimEntitlements.findIndex((item) => item.OID == entitlement.OID)
    let newEntitlement = new Entitlement({
      Name: entitlement.Name,
      Version: entitlement.Version,
      OID: entitlement.OID,
      Description: entitlement.Description,
      Privilege: entitlement.Privilege,
    })
    if (itemFound < 0) {
      cloneParent.PrimEntitlements.push(newEntitlement)
      this.saveRole(cloneParent).subscribe((item) => {
        resetForm()
      })
    } else {
      this.saveSingleEntitlement(newEntitlement).subscribe((item) => {
        resetForm()
      })

    }


  }
  saveRole(application: any) {

    return this.server.saveAbstractSecurity(null, null, application)

  }
  saveSingleEntitlement(entitlement: Entitlement) {
    return this.server.saveEntitlement(null, entitlement)

  }

  rootlevel: any;

  constructor(_server: AccessService, private auth: AuthService) {
    this.server = _server; // fix compile duplicate 
  }
  public server: AccessService;


}

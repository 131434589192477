import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
// import Mediator from '../core-services/mediator/fixes.mediator';
import { Title } from '@angular/platform-browser';
import { Observable, of, Subscriber } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Mediator as MediatorMarket } from '../core-services/mediator/market-utilities.mediator';

import { AddOrganizationDialogComponent } from '../add-organization-dialog/add-organization-dialog.component';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { AbstractSecurityElement, Entitlement, Organization, Perspective, Role, SecurityApplication } from '@smartobjx/smart.objx.models';
import { CdkDragDrop, moveItemInArray, transferArrayItem, copyArrayItem, CdkDrag } from '@angular/cdk/drag-drop';
import { StructureBranchEditorCdkComponent } from '../structure-branch-editor-cdk/structure-branch-editor-cdk.component';
import { PermissionDrawerComponent } from '../permission-drawer/permission-drawer.component';
import * as _ from "lodash";
import { AuthService } from '../core-services/authentication/auth.service';
import { CustomValidator } from '../shared/Validation';
import { ApplicationBrowserComponent } from '../application-browser/application-browser.component';
import { RoleStructureComponent } from '../role-structure/role-structure.component';
import { EntitlementEditorComponent } from '../entitlement-editor/entitlement-editor.component';
import Mediator from '../core-services/mediator/access.mediator';

const isOfType = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);
const isFile = (name: string) => name.split('.').length > 1;

@Component({
  selector: 'access-adm',
  templateUrl: './access-adm.component.html',
  styleUrls: ['./access-adm.component.scss']
})

export class StructuresAdmComponent implements OnInit ,AfterViewInit{
  @ViewChild(ApplicationBrowserComponent, { static: false }) childBrowserComponent: ApplicationBrowserComponent;
  @ViewChild(StructureBranchEditorCdkComponent, { static: false }) child: StructureBranchEditorCdkComponent;
  @ViewChild(RoleStructureComponent, { static: false }) roleStructureComponent: RoleStructureComponent;
  @ViewChild(EntitlementEditorComponent, { static: false }) entitlementEditorComponent: EntitlementEditorComponent;


  selectedOrganization: Organization;
  @ViewChild(PermissionDrawerComponent, { static: false }) PermissionDrawerComponent: PermissionDrawerComponent;
  parentNode: any;
  model: SecurityApplication;
  newApplication: any;
  roleSelected: Role;
  applicationSelected: SecurityApplication
  openEntitlement: boolean
  loadash: _.LoDashStatic;
  autosizeContainer: boolean;
  constructor(private mediator: Mediator, private mediatorMarket: MediatorMarket, title: Title, public _dialog: MatDialog, private auth: AuthService) {
    this.subscribe(subscriber => {
      this.permissionListener = subscriber;
    }, (data: any) => {
      console.log(data);
      this.showPermissions = true;
    }
    );
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.autosizeContainer = true
    }, 100);
  }


  validateTab(states: Number[]) {
    return this.drawerState.some(x => { return states.includes(x) })
  }
  ngOnInit() {
    this.loadash = _
    
    this.mediator.LoadingObservable.subscribe((loading) => {
      this.isLoading = loading
    })
  }
  evenPredicate(item: CdkDrag<number>) {
    // console.log(item.data);
    return true;
    // return item.data % 2 === 0;
  }
  selectApplication(app) {
    this.applicationSelected = app
    console.log(this.applicationSelected)
  }
  closeBrowser(Event) {
    this.model = Event.model
    this.applicationSelected = Event.model
    this.newApplication = Event.newApplication

    this.drawerState = [0,1,2]

  }
  closetabEntitlement(event){
    this.openEntitlement=false;this.drawerState = [1,2,3];this.refreshApplication(event)

  }


  openEntitlementTab(entitlement: any) {
    this.openEntitlement = true
    this.drawerState = [3]
    setTimeout(() => {
      this.entitlementEditorComponent.LoadModel(entitlement.role, entitlement.entitlement)

    }, 400);
  }
  refreshApplication(event) {
    let self = this
    this.openEntitlement = false;
    function refresh() {
      self.roleStructureComponent.refreshModel()
      self.mediator.LoadingObservable.next(false)
      self.model = self.childBrowserComponent.model.find(app => self.model.OID == app.OID)
      if (!_.isNil(event) && !_.isBoolean(event)) {
        self.roleSelected = event

      }else if(!_.isNil(self.roleSelected)){
        let roleSelected = (self.model as any).PrimChildren.find(x => x.OID == self.roleSelected.OID)
        self.roleSelected = roleSelected
      }


    }

    
    this.childBrowserComponent.GetSecurityApplication(this.applicationSelected.OID,refresh)
  



  }
  closeApplication(Event) {
    this.model = null
    this.drawerState = [0,1]
    this.applicationSelected = null
    if (Event == true) {
      this.childBrowserComponent.GetAllAppications()
    }
  }

  closeRole($event) {
    this.drawerState = [0,1,2]
    if ($event == true) {
      this.childBrowserComponent.GetSecurityApplication(this.applicationSelected.OID)
    }
  }
  drop(event: CdkDragDrop<{ title: string, poster: string }[]>, parent: any) {
    // moveItemInArray(this.movies, event.previousIndex, event.currentIndex);

    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.updateStructure();
    } else {
      let item = event.previousContainer.data[event.previousIndex] as any;
      // console.log(event.previousContainer);
      if (event.previousContainer.element.nativeElement.classList.contains('drawer-list')) {
        if (!this.organizationsInStructure.filter(o => o.Name === item.Organization.Name).length) { // is not in the structure
          console.log('create copy');
          copyArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
        }
      } else {
        if (item != parent) {
          transferArrayItem(event.previousContainer.data,
            event.container.data,
            event.previousIndex,
            event.currentIndex);
          this.updateStructure();
        }
      }
    }
  }

  openRole($event: Role) {
    this.openEntitlement = false
 //   if (this.loadash.some(this.drawerState, x => x == 3)) {
 //     this.drawerState = [2]
 //   }
    setTimeout(() => {
      this.drawerState = [1,2,3]
      this.roleSelected = $event
    }, 500);




  }

  DeleteAllRelationship($event){
    this.roleStructureComponent.DeleteAllLink($event)
  }

  DeleteNode($event){
    this.roleStructureComponent.DeleteNode($event)
  }
  //#region methods
  private deepChangeColor(item: any, color: string, list: any[]) {
    let results = list.filter(o => o.Organization.Name === item.name);
    if (results.length) {
      list.forEach(o => o.Color = color);
    } else {
      list.forEach(o => {
        this.deepChangeColor(item, color, o.Units);
      });
    }
  }

  changeColor(item: any) {
    const color = this.getRandomColor();
    const node = this.selectedPerspective.Tree;
    if (node.Organization.Name === item.name) {
      node.Color = color;
    } else {
      if (node.Partners) this.deepChangeColor(item, color, node.Partners);
      this.deepChangeColor(item, color, node.Units);
    }
    this.updateStructure();
  }
  editBranch(item: any) {
    const fn = (i: any) => {
      if (i.Organization.Name === item.name) {
        this.selectedBranch = i;
      } else {
        this.parentNode = i;
        i.Units.forEach(e => {
          fn(e);
        });
      }
    }
    const node = this.selectedPerspective.Tree;
    if (!_.isNil(node)) {
      fn(node);
    }
  }
  addPartnerToPerspective(partner: any) {
    const node = this.selectedPerspective.Tree;
    node.Partners = node.Partners || [];

    node.Partners.push({
      Organization: partner,
      Tag: 'Partner',
      Units: [],
      Color: node.Partners.length ? node.Partners[0].Color : undefined
    });
    this.updateStructure();
  }
  private deepUpdateTag(type: string, item: any, node: any) {
    if (node.Organization.Name === item.name) {
      node.Tag = type;
    } else {
      node.Units.forEach(o => {
        this.deepUpdateTag(type, item, o);
      });
    }
  }
  changeType(item: any) {
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: true,
      data: {
        title: 'Change tag',
        // titleClass: 'warning',
        // matIcon: 'warning_amber',
        button1Text: 'Cancel',
        button2Text: 'Change',
        button1Color: 'primary',
        content: 'Change the tag for:\r\n <b>' + item.name + '</b>',
        useField: true,
        value: item.type
      }
    }).afterClosed().toPromise()
      .then(tag => {
        if (tag) {
          this.deepUpdateTag(tag, item, this.selectedPerspective.Tree);
          this.updateStructure();
        }
      });
  }
  //#region perspectives


  configPerspective(el: any) {
    this.editingClientAPI = this.selectedPerspective.ClientAPI;
    this.openDialog(el, null).toPromise();
  }
  setClientAPI() {
    this.selectedPerspective.ClientAPI = this.editingClientAPI;
  }



  private deepUpdate(child: any, parent: any, node: any, type: string, level: number) {
    if (node.Organization.Name === (parent.name || parent.Organization.Name)) { // temp fix
      // if(node.Organization.Name === parent.Organization.Name){
      node.Units.unshift(this.newPerspectiveElement(child, type, this.colorLevels.length > level ? this.colorLevels[level] : undefined));
    } else {
      node.Units.forEach(o => {
        this.deepUpdate(child, parent, o, type, level + 1);
      });
    }
  }
  addChildAs(item: any, type: string, parent: any) {
    if (parent) {
      this.deepUpdate(item, parent, this.selectedPerspective.Tree, type, 1);
    } else {
      this.selectedPerspective.Tree = this.newPerspectiveElement(item, type);
    }
    this.updateStructure();
  }
  addChild(item: Organization, parent: Organization) {
    let type = this.getTypeByParent(parent);
    this.addChildAs(item, type, parent ? { name: parent.Name } : undefined);
  }
  private getTypeByParent(parent: Organization) {
    const node = this.selectedPerspective.Tree;
    if (node) {
      const getType = (t: string) => t === 'Subscriber' ? 'Client' : 'Customer';
      const search = (list: any[]) => {
        for (let i = 0; i < list.length; i++) {
          const item = list[i];
          if (parent.Name === item.Organization.Name) {
            return getType(item.Tag);
          } else {
            const r = search(item.Units);
            if (r) {
              return r;
            }
          }
        }
      };
      return search([node]);
    } else {
      return 'Subscriber';
    }
  }
  private newPerspectiveElement(item: Organization, tag: string, color: string = undefined) {
    return {
      Organization: item,
      Tag: tag,
      Units: [],
      Color: color || this.getRandomColor()
    }
  }
  private getRandomColor() {
    return '#' + (Math.random() * 0xFFFFFF << 0).toString(16);
  }
  private simpleWarning(message: string, callback: () => void) {
    this._dialog.open(SimpleDialogComponent, {
      panelClass: 'smart-objx',
      autoFocus: false,
      data: {
        title: 'Attention',
        titleClass: 'warning',
        matIcon: 'warning_amber',
        content: message,
        button1Text: 'Cancel',
        button2Text: 'OK',
        button1Color: 'primary'
      }
    }).afterClosed().toPromise()
      .then(action => {
        if (action) {
          callback();
        }
      });
  }
  removeFromGraph(item: any, force: boolean = false) {
    const node = this.selectedPerspective.Tree;
    if (node.Organization.Name === item.name) {
      if (!force && (node.Units.length || (node.Partners && node.Partners.length))) {
        this.simpleWarning('The item has children \r\nRemove anyway?', () => this.removeFromGraph(item, true))
      } else {
        this.selectedPerspective.Tree = null;
        this.structure = [];
      }
    } else {
      this.removeItemFromPartners(item);
      this.removeItem(item);
    }
  }
  private deepRemove(item: any, node: any, force: boolean = false) {
    let results = node.Units.filter(o => o.Organization.Name === item.name);
    if (results.length) {
      let i = node.Units.indexOf(results[0]);
      if (!force && node.Units[i].Units.length) {
        this.simpleWarning('The item has children \r\nRemove anyway?', () => {
          this.deepRemove(item, node, true);
          this.updateStructure();
        })
      } else {
        node.Units.splice(i, 1);
      }
    } else {
      node.Units.forEach(o => {
        this.deepRemove(item, o);
      });
    }
  }
  removeItem(item: any) {
    this.deepRemove(item, this.selectedPerspective.Tree);
    this.updateStructure();
  }
  removeItemFromPartners(item: any) {
    const node = this.selectedPerspective.Tree;
    if (node.Partners && node.Partners.length) {
      const results = node.Partners.filter(o => o.Organization.Name === item.name);
      if (results.length) {
        let i = node.Partners.indexOf(results[0]);
        node.Partners.splice(i, 1);
        this.updateStructure();
      }
    }
  }

  private setOrganizations(organizations: any) {
    this.organizations = organizations.filter(o => !o.IsPartner);
    this.partners = organizations.filter(o => o.IsPartner);

    const notAdded = this.organizations.filter(o => !this.organizationsInStructure.filter(x => x.Name === o.Name).length);

    this.itemsAsPerspectiveElements = notAdded.map(o => {
      return {
        Organization: o,
        Tag: 'new',
        Units: []
      }
    });
  }
  private removeOrganization(organization: any) {
    const organizations = this.organizations.slice().concat(this.partners.slice()); // join orgs and partners in a single collection
    const index = organizations.indexOf(organization); // look for the record.
    organizations.splice(index, 1);
    this.setOrganizations(organizations); // replace old data
  }
  private addOrganization(organization: any) {
    const organizations = this.organizations.slice();
    organizations.push(organization);
    this.setOrganizations(organizations.concat(this.partners.slice()));
  }

  private openDialog(el: any, config: any) {
    const dialogRef = this._dialog.open(el, config);
    return dialogRef.afterClosed();
  }
  private subscribe(register: (subscriber: Subscriber<any>) => void, next: (data: any) => void) {
    const observable = new Observable(register);

    observable.subscribe({
      next,
      error: e => {
        console.error(e);
      },
      complete: () => console.log('finished')
    });
  }

  private getAsStructure(tree: any) {
    const { Organization, Tag, Units, Partners, Color } = tree;
    return {
      name: Organization.Name,
      type: Tag,
      permissions: {
        // hide: true,
      },
      partners: Partners && Partners.length ? Partners.map(o => this.getAsStructure(o)) : [],
      children: Units.map(o => this.getAsStructure(o)),
      color: Color ? Color : Tag === 'Subscriber' ? null : Tag === 'Client' ? '#FF0C3E' : Tag === 'Partner' ? '#FFA000' : '#33B700',
    };
  }
  private updateStructure() {
    if (this.selectedPerspective && this.selectedPerspective.Tree) {
      this.structure = [this.getAsStructure(this.selectedPerspective.Tree)];
    } else {
      this.structure = [];
    }
  }
  private setPerspective(perspective: any, isNewPerspective: boolean = false) {
    if (this.child) {
      this.child.closeEditBranch();
    }
    this.selectedPerspective = perspective;
    this.updateStructure();
    //this.isLoading = true
    if (!isNewPerspective && !_.isNil(perspective.OID)) {
      setTimeout(() => {
        // this.editBranch({ name: this.auth.userName });
        // this.child.editBranch(this.selectedBranch)
        // this.mediator.rootlevel = this.selectedBranch
      }, 200);
    }
    // const organizations = this.getOrganizationsFromStructure(perspective.Tree);
    // console.log(this.organizations);
    // console.log(organizations);
    // this.availableOrganizations = this.organizations.filter(o => !~organizations.map(o => o.Name).indexOf(o.Name) );
  }


  //#endregion



  permissionListener: Subscriber<any>;
  get zoom(): string {
    // return 'scale(2);';
    return `scale(${this.zoomLevel})`;
  }
  isLoading: boolean;
  perspectivesAreLoading: boolean;
  showPermissions: boolean = false;
  loadingOrganizations: boolean = true;
  organizations: Organization[];
  partners: Organization[];
  perspectives: any[];
  selectedPerspective: any;
  selectedBranch: any; // selectedPerspective.Tree
  itemsAsPerspectiveElements: any[] = [];
  drawerState = [0,1];
  editingClientAPI: string;

  private getOrganizationsFromStructure(el: any): Organization[] {
    return [].concat.apply([el.Organization], el.Units.map(o => this.getOrganizationsFromStructure(o)));
  }
  get partnersInStructure(): Organization[] {
    if (!this.selectedPerspective || !this.selectedPerspective.Tree || !this.selectedPerspective.Tree.Partners) return [];
    return this.selectedPerspective.Tree.Partners.map(o => o.Organization);
  }
  get organizationsInStructure(): Organization[] {
    if (!this.selectedPerspective || !this.selectedPerspective.Tree) return [];
    const organizations = this.getOrganizationsFromStructure(this.selectedPerspective.Tree);
    return organizations;
  }
  get availableOrganizations(): any[] {
    if (!this.selectedPerspective || !this.organizations) return [];
    const organizations = this.organizationsInStructure;
    let list = this.organizations.filter(o => !~organizations.map(x => x.Name).indexOf(o.Name));
    // console.log(list);
    return list;
  }
  get availablePartners(): any[] {
    if (!this.selectedPerspective || !this.partners) return [];
    const partners = this.partnersInStructure;
    let list = this.partners.filter(o => !~partners.map(x => x.Name).indexOf(o.Name));
    // console.log(list);
    return list;
  }
  get customersForMarket(): any {
    const organizations = typeof this.organizations != 'undefined' ? this.organizations.map(o => { return { Name: o.Name, OID: o.OID, Checked: false, Show: true, Disabled: false } }) : [];
    return organizations;
  }
  structure: any[] = [];
  structure1: any[] = [{
    name: 'Experian',
    type: 'Subscriber',
    permissions: {
      hide: true,
    },
    partners: [{
      name: 'Sterling',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Good Hire',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Accurate Now',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }],
    children: [{
      name: 'Real Page',
      type: 'Client',
      color: '#FF0C3E',
      permissions: {
        manageDisabled: false,
        viewDisabled: true,
      },
      children: [{
        name: 'Aporeto',
        type: 'Customer',
        color: '#33B700',
        permissions: {
          manageDisabled: true
        },
        children: []
      }]
    }, {
      name: 'Intuit',
      type: 'Customer',
      color: '#FF0C3E',
      permissions: {
        manageDisabled: true
      },
      children: [{
        name: 'Intuit West',
        type: 'Customer',
        color: '#33B700',
        permissions: {
          disabled: false
        },
        children: []
      }, {
        name: 'Intuit International',
        type: 'Customer',
        color: '#33B700',
        // children: []
        permissions: {
          disabled: false
        },
        children: [{
          name: 'Americas',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }, {
          name: 'Europa',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }, {
          name: 'Asia',
          type: 'Customer',
          color: '#33B700',
          permissions: {},
          children: []
        }]
      }, {
        name: 'Intuit East',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }]
      // }, {
      //     name: 'National Grid',
      //     type: 'Client',
      //     color: '#FF0C3E',
      //     permissions: {},
      //     children: []
    }]
  }];
  structure2: any[] = [{
    name: 'Intuit',
    type: 'Customer',
    color: '#FF0C3E',
    permissions: {
      manageDisabled: true
    },
    partners: [{
      name: 'Sterling',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Good Hire',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }, {
      name: 'Accurate Now',
      type: 'Partner',
      color: '#FFA000',
      children: []
    }],
    children: [{
      name: 'Intuit West',
      type: 'Customer',
      color: '#33B700',
      permissions: {
        disabled: false
      },
      children: []
    }, {
      name: 'Intuit International',
      type: 'Customer',
      color: '#33B700',
      // children: []
      permissions: {
        disabled: false
      },
      children: [{
        name: 'Americas',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }, {
        name: 'Europa',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }, {
        name: 'Asia',
        type: 'Customer',
        color: '#33B700',
        permissions: {},
        children: []
      }]
    }, {
      name: 'Intuit East',
      type: 'Customer',
      color: '#33B700',
      permissions: {},
      children: []
    }]
  }];
  zoomLevel: number = 1;

  public data: any[] = [
    {
      text: 'Furniture', items: [
        { text: 'Tables & Chairs' },
        { text: 'Sofas' },
        { text: 'Occasional Furniture' }
      ]
    },
    {
      text: 'Decor', items: [
        { text: 'Bed Linen' },
        { text: 'Curtains & Blinds' },
        { text: 'Carpets' }
      ]
    }
  ];

  draggingItem: any;

  private colorLevelChecker(level: any[], list: string[]) {
    const merged = [].concat.apply([], level.map(o => o.children));
    if (merged.length) {
      list.push(merged[0].color);
      const withChildren = merged.filter(o => o.children.length);
      if (withChildren.length) {
        this.colorLevelChecker(withChildren, list);
      }
    }
  }
  get colorLevels(): string[] {
    let list = ['red', 'green', 'blue'];
    if (this.structure.length) {
      const subscriber = this.structure[0];
      list = [subscriber.color || 'blue'];

      this.colorLevelChecker(this.structure, list);
    }
    return list;
  }
  get colorLevelsBySelectedBranch(): string[] {
    const branch = this.selectedBranch;
    if (!branch) return this.colorLevels;


    const countLevel = (list: any, count: number = 0) => {
      return !!~list.indexOf(branch) ? count : countLevel([].concat.apply([], list.map(o => o.Units)), count + 1);
    };

    let level = countLevel([this.selectedPerspective.Tree]);

    return this.colorLevels.slice(level);
  }

  test(ev: any) {
    console.log(ev);
  }
  //#region angular drag and drop API

  //#endregion
}

import { Component, Input, OnInit, OnDestroy, ViewChild, EventEmitter, Output } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import { Configuration, AbstractConfiguration, AbstractSecurityElement, SecurityApplication, Application, Role, Entitlement, Privilege } from '@smartobjx/smart.objx.models';
import { SimpleDialogComponent } from '../simple-dialog/simple-dialog.component';
import { Tools } from '../shared/Tools';
import { AuthService } from '../core-services/authentication/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import Mediator from '../core-services/mediator/access.mediator';
import { CustomValidator } from '../shared/Validation';

@Component({
    selector: 'role-editor',
    templateUrl: './role-editor.component.html',
    styleUrls: ['./role-editor.component.scss'],
})
export class RoleEditorComponent implements OnInit, OnDestroy {
    @Input() newRole: boolean;
    newApplication
    @Output() openEntitlement = new EventEmitter<any>();
    @Output() closeEntitlement = new EventEmitter<boolean>();
    @Output() refreshApplication = new EventEmitter<any>();
    inheritedEntitlements = []
    roleSelected
    isActive
    isLoading = false
    constructor(
        private mediator: Mediator,
        public _dialog: MatDialog,
        private fb: UntypedFormBuilder,
        private _authService: AuthService,
        private _info: MatSnackBar
    ) {

        this.minDate = new Date();
        this.advOptEnabled = false;
        this.pov = this._authService.getPOV();
    }

    get model(): SecurityApplication {
        return this.i_Model;
    }

    @Input()
    set model(newModel: SecurityApplication) {
        this.i_Model = newModel;
        this.selectedIndex = -1;
    }
    @Input()
    set roleModel(roleModel: Role) {
        this.i_RoleModel = roleModel;
        this.selectedIndex = -1;
    }
    get roleModel(): Role {
        return this.i_RoleModel;
    }
    get selectedIndex(): number {
        return this.i_SelectedIndex;
    }

    set selectedIndex(clickedItemIndex: number) {
        this.i_SelectedIndex = clickedItemIndex;
    }


    get formIsDisabled(): boolean {
        return !this.form || (this.form.pristine && this.deleteStack.length === 0) || !this.form.valid;
    }
    get priorToParent(): boolean {
        const now = new Date();
        return now < this.minDate;
    }

    // the parent date (will be used as minDate)

    minDate: Date;
    initialDate: Date; // the current version date
    advOptEnabled: boolean;

    get isNew(): boolean {
        return !!this.roleModel && !this.roleModel.OID;
    }
    private get isUntouched(): boolean {
        return this.form.pristine && this.deleteStack.length == 0;
    }

    private i_startDate: Date = new Date();
    startDateForceInvalid: boolean = false;
    // #endregion

    // #region Data Elements
    private i_Model: SecurityApplication;
    private i_RoleModel: Role;


    private i_SelectedIndex: number;
    private form: UntypedFormGroup;
    private deleteStack: any[] = [];
    private pov: string;
    versionsIsLoading = false




    @ViewChild('nameInput', { static: false }) nameInputRef: any;
    private disableFocused: boolean = false;
    fixDateValue: string;
    @ViewChild('startDateMinVerification', { static: false }) startDateMinVerificationDialog: any;
    // #endregion

    // #region Event Emitters
    // #endregion
    // #region Event Handlers

    // #region public
    editRole($event) {
        this.openEntitlement.emit({ role: this.roleModel, entitlement: $event })
    }
    appendEntitlement() {
        let entitlement = new Entitlement()
        entitlement.Privilege = new Privilege({ Name: 'Description' })
        this.openEntitlement.emit({ role: this.roleModel, entitlement: entitlement })
    }

    fixHeightInheritedEntitlements() {
        if ((this.roleModel as any).PrimEntitlements?.length == 0) {
            return ' 36vh'
        } else if (this.inheritedEntitlements?.length == 0) {
            return '5vh'
        } else {
            return '22vh'
        }

    }

    fixHeightEntitlements() {
        if ( this.inheritedEntitlements?.length == 0) {
            return ' 36vh'
        } else if ((this.roleModel as any).PrimEntitlements?.length == 0) {
            return '5vh'
        } else {
            return '22vh'
        }
    
    }
    onSaveModel() {
        if (this.isUntouched) return;
        const date = this.i_startDate;
        if (this.checkDateValues(date)) {
            let model = this.mergeFormToModel();
            this.roleModel = model;
            const resetForm = () => {
                this.form.reset();
                this.deleteStack = [];
            };

            const refresh = (role = null) => {
                this.refreshApplication.emit(role)
            };

            if (this.deleteStack.length) {
            } else {
                this.mediator.saveAbsctract(this.model, model, refresh);
            }

        } else {
            this.openDialog(this.startDateMinVerificationDialog);
        }
    }

    openDialog(el: any, options: any = null) {
        return this._dialog.open(el, options);
    }
    checkStartDateBefore(fn: (startDate: Date) => void) {
        const startDate = this.i_startDate;
        if (!startDate) {
            this.fireStartDateError();
            return;
        }

        const now = new Date();
        const version = startDate > now ? startDate : now;

        fn(version);
    }
    // #region from view

    appendRole(settingModel = null) {


    }


    appendConfig(configurationModel: Configuration = null) {

        // this.checkStartDateBefore(startDate => {
        //     this.selectedIndex = -1;
        //     if (configurationModel != null && configurationModel != undefined) {
        //         this.mediator.editConfiguration(configurationModel, this.view, this.model, startDate, this, this.versionDateData,this.viewController);
        //     } else {
        //         this.mediator.newConfiguration(this.view, this.model, startDate, this,this.viewController);
        //     }
        // });
    }

    onVersions(record: any, date: Date, versionDates: any[]) {
        //   this.mediator.showConfigVersions(
        //       record,
        //       date,
        //       versionDates,
        //       () => this.selectedIndex = -1,this.viewController
        //   );
        //   this.updateSelected(record);
    }

    fireStartDateError() {
        this._info.open('Start Date must be valid before this action', '', {
            duration: 3000,
            verticalPosition: 'top',
            horizontalPosition: 'end',
            panelClass: 'info-warn'
        });
    }

    onDisableOrEnable(config: AbstractConfiguration) {
        this.updateSelected(config);
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: `Yes, delete it`,
                content: `Do you really want to delete the `
                    + this.getType(config)
                    + ` "<b>${config.Name}</b>" ?`
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur(); // fix force blur on x
                this.selectedIndex = -1;
                if (action) {
                    this.stackToDelete({ configuration: config })
                }
            });
    }

    onDelete(config: AbstractConfiguration) {
        this.updateSelected(config);
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Are you sure?',
                titleClass: 'error',
                matIcon: 'delete_forever',
                content: 'Do you really want to want to delete the '
                    + this.getType(config)
                    + ` "<b>${config.Name}</b>" ?`
                    + '\r\nThis process cannot be undone.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                (document.activeElement as any).blur();
                if (action) {

                    let primChildren = (this.roleModel as any).PrimChildren,
                        selectedIndex = primChildren.indexOf(config);
                    primChildren.splice(selectedIndex, 1);
                    this.stackToDelete({ configuration: config })

                    //     this.viewController.clearBy(this.model);
                }
            });
    }
    onChanges() {
        (this.form as any).pristine = false;
    }
    checkDateValues(date: Date) {
        return date >= this.minDate;
    }

    toggleAdvancedOptions() {
        this.advOptEnabled = !this.advOptEnabled;
    }

    simpleDate(date: any) {
        return Tools.dateToString(date);
    }
    markStartDateAsDirty() {
        this.startDateForceInvalid = true;
    }
    fixStartDateAndSave() {
        this.i_startDate = this.priorToParent ? this.minDate : new Date();
        this.onSaveModel();
    }
    fixMinDate(date: Date) {
        const diff = this.minDate.getTime() - date.getTime();
        if (diff > 0 && diff < 100) {
            this.minDate = date;
        }
    }
    // #endregion

    // #endregion

    // #region private
    private checkStartDate() {
        //   const { startDate } = this;
        //   if (startDate) {
        //       this.minDate = startDate;
        //   }
    }
    private updateSelected(rule: AbstractConfiguration) {
        this.selectedIndex = (this.roleModel as any).PrimChildren.indexOf(rule)
    }

    private getType(record: any) {
        let type = (record as any).$type;
        if (!type) return 'undefined';
        if (type.includes('Settings.Configuration,')) return 'configuration';
        if (type.includes('Settings.Setting,')) return 'setting';
        if (type.includes('Settings.SecureSetting,')) return 'setting';
    }

    private getControl(name: string): UntypedFormControl {
        return this.form.get(name) as UntypedFormControl;
    }

    private mergeFormToModel() {

        let model = Object.assign({}, this.roleModel as any);
        model.Name = this.form.value.name;
        model.Description = this.form.value.description;
        model.Version = this.i_startDate;
        //model.PrimChildren = !_.isNil((this.roleModel as any).PrimChildren) && (this.roleModel as any).PrimChildren.length > 0 ? (this.roleModel as any).PrimChildren : null
        return model;
    }

    private stackToDelete(data: any) {
        let filter = this.deleteStack.filter(o => o.configuration === data.configuration);
        if (filter.length) {
            const i = this.deleteStack.indexOf(filter[0]);
            this.deleteStack.splice(i, 1);
        } else {
            this.deleteStack.push(data);
        }
    }
    private showWarning() {
        this.openDialog(SimpleDialogComponent, {
            panelClass: 'smart-objx',
            autoFocus: false,
            data: {
                title: 'Attention',
                titleClass: 'warning',
                matIcon: 'warning_amber',
                button1Text: 'Yes, close the panel',
                button2Text: 'No, keep the panel open',
                button1Color: 'primary',
                content: 'Are you sure you want to close this panel?'
                    + '\r\nThe changes won´t be saved.'
            }
        }).afterClosed().toPromise()
            .then(action => {
                if (action) {
                    //    this.view.close(true);
                }
            });
    }
    private registerEvents() {
        // this.view.Events.onClose$ = () => {
        //     if (!this.isUntouched) {
        //         this.showWarning();
        //     }
        //     return this.isUntouched;
        // };
    }
    // #endregion
    // #endregion

    // #region Construction & Finalization

    CloseRoleBtn() {
        console.log(close)
        this.closeEntitlement.emit(false)
    }


    ngOnDestroy() {
    }

    ngOnInit() {
        this.checkStartDate();
        this.registerEvents();
        let model = this.roleModel;
        if (typeof model.Version === 'string' && !!model.Version) {
            model.Version = CustomValidator.ensureDate(model.Version);
        }
        this.form = this.fb.group({
            name: [{ value: model.Name, disabled: false }, Validators.required],
            description: [model.Description],

        })
        this.i_startDate = new Date(model.Version.getTime());
        this.initialDate = model.Version;
        this.fixMinDate(model.Version);
        this.LoadInheritedEntitlements();
    }
    ngOnChanges(changes: any): void {
        console.log(this.form)
        this.checkStartDate();
        if (changes.roleModel && !changes.roleModel.firstChange) {
            let model = changes.roleModel.currentValue;

            if (typeof model.Version === 'string' && !!model.Version) {
                model.Version = CustomValidator.ensureDate(model.Version);
            }
            this.form.patchValue({
                name: model.Name,
                description: model.Description,

            });
            this.i_startDate = new Date(model.Version.getTime());
            this.initialDate = model.Version;
        }

        // focus name
        if (changes.isLoading && changes.isLoading.currentValue == false && !this.disableFocused) {
            setTimeout(() => {
                this.nameInputRef.nativeElement.focus();
                this.disableFocused = true;
            }, 0);
        }
        this.LoadInheritedEntitlements();
    }




    private LoadInheritedEntitlements() {
        if (_.isNil(this.model) || _.isNil(this.roleModel) || _.isNil((this.roleModel as any).PrimParentRoles)) {
            return;
        }
        const GetInheritedEntitlements = (roleModel, inherited) => {
            roleModel.PrimParentRoles.forEach((element) => {
                element.PrimEntitlements.forEach(entitlement => {
                    entitlement.Name = `${entitlement.Name} - ${element.Name}`;
                });
                inherited = inherited.concat(element.PrimEntitlements);
                inherited = GetInheritedEntitlements(element, inherited);
            });
            return Array.from(new Set(inherited));
        };

        const uniqueOIDs = new Set();
        const cloneRoles = _.cloneDeep(this.roleModel);
        const inherited = GetInheritedEntitlements(cloneRoles, []);

        this.inheritedEntitlements = (inherited as Array<Entitlement>).filter(obj => {
            if (!uniqueOIDs.has(obj.OID)) {
                uniqueOIDs.add(obj.OID);
                return true;
            }
            return false;
        });

        console.log(this.inheritedEntitlements);
    }
}